// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap');

// Defaults
body,
*{
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
}

body{
    background-color: white;
}

a, a:link, a:active, a:visited{
    text-decoration: none;
    color: unset;
}

img{
    image-rendering: -webkit-optimize-contrast;
}

.landingpage, 
.bodybg{
    background: unset;
}

.flex-center{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

// Theme Styles
.au-theme{
    margin: 0px;
    padding: 0px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.au-btn{
    display: block;
    max-width: 220px;
    text-align: center;
    padding: 15px 40px;
    font-size: 1.2em;
    font-weight: 600;
    letter-spacing: 1px;
    color: white !important;
    border-radius: 30px 0px 30px 0px;
    text-decoration: none;
    background: rgb(217,111,92);
    background: linear-gradient(90deg, rgba(217,111,92,1) 0%, rgba(230,151,90,1) 100%);
}

.au-btn--previous{
    padding: 10px 40px;
}

// Header
header{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    padding: 40px 0px;
}

.header-logo{
    width: 100%;
    max-width: 350px;
}

// Page Content
.page-content{
    width: 100%;
    padding: 50px 100px 50px 100px;
    background-color: #468acf;
    border-top: 20px solid #d96e5c;
    border-radius: 0px 0px 120px 0px;
}

.page-content h1{
    text-align: center;
    color: white;
    font-size: 3.5em;
    font-weight: 700;
    margin: 0px;
    letter-spacing: 1px;
}

.page-content--congratulations h3{
    font-weight: bold;
    font-size: 1.5em;
    color: #1e2953;
}

.page-content--questions .questions-subtitle{
    text-align: center;
    color: white;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.5em;
    margin: 0px;
}

// Questions
.question-title{
    font-family: 'Quicksand', sans-serif;
    text-align: center;
    color: white;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.5em;
}

.answer_item_wrap .question-choice{
    font-family: 'Quicksand', sans-serif;
    text-align: center;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    letter-spacing: 1px;
}

.equal-half{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.equal-half--bottom{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;
}

.equal-half-part{
    width: 50%;
}

// Homepage
.video-container{ 
    position: relative; 
    padding-bottom: 58.25%; 
    padding-top: 0px; 
    height: 0; 
    overflow: hidden; 
}

.video-container iframe{ 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    border: 10px solid white;
}

.hp-list{
    padding-left: 0px;
    margin-left: 70px;
}

.hp-list li{
    margin: 15px 0px 15px 0px;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.5em;
    color: white;
}

.equal-half p{
    margin-bottom: 0px;
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-weight: 600;
    color: lightgray;
    line-height: 1.5em;
}

// Footer
footer{
    margin-top: 20px;
    padding: 0px 20px;
}

.footer-disclaimer{
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1.5em;
    text-align: center;
    color: #0079be;
}

.privacy-policy{
    margin-top: 30px;
    margin-bottom: 20px;
}

.privacy-policy{
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1.5em;
    text-align: center;
    color: black;
}

.privacy-policy a{
    text-decoration: underline;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    text-align: center;
    color: black;
}

.container{
    width: 100%;
}

.page-content--questions,
.page-content--congratulations{
    padding: 30px 100px;
}

.question-div-main{
    width: 100%;
    margin-left: 0px;
    background: none;
}

.questions_body{
    margin-top: 60px;
}

.proxy_radio{
    border: none;
    background-color: white;
}

.answer_item_wrap:hover > .proxy_radio{
    background-color: #142556;
}

.answer_item_wrap:hover > .question-choice{
    color: #142556;
}

.progressbar_wrap{
    margin: 0px;
    border: none;
}

.rate{
    border-right: 2px solid #468acf;
}

.rate-number{
    font-size: 18px;
    font-weight: bold;
}

.rate-1{
    background-color: #1977bd;
}

.rate-2{
    background-color: #196cb0;
}

.rate-3{
    background-color: #1762a5;
}

.rate-4{
    background-color: #145897;
}

.rate-5{
    background-color: #174d8b;
}

.rate-6{
    background-color: #15417e;
}

.rate-7{
    background-color: #143872;
}

.rate-8{
    background-color: #112e66;
}

.rate-9{
    background-color: #142556;
}

.analyzing-answer{
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 1px;
    color: white;
    font-size: 1.2em;
}

.au-theme .section{
    padding: 0px;
    margin: 0px;
    float: unset;
}

.choices-div .choice-radio .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid black;
    margin-right: 7px;
    display: inline-block;
}

.choice-default .circle{
    background-color: black;
}

.take-test-for,
.take-test-for-choices,
.choice-radio{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 0.8em;
}

.choice-radio{
    gap: 0px;
    font-weight: bold;
    font-size: 1.5em;
}

.choice-radio span{
    color: #1e2953;
}

.take-test-for h3{
    margin: 0px;
}

.page-content--congratulations .opt-form{
    margin-top: -20px;
}

.page-content--congratulations #name, 
.page-content--congratulations #email{
    font-weight: bold;
    font-size: 1.2em;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: -10px;
}

.page-content--congratulations .au-btn{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border: none;
    padding: 10px 40px;
}

.page-content--congratulations .form-group{
    margin-bottom: 0px;
}

.medical-disclaimer{
    text-align: center;
    color: white;
    font-family: 'Open Sans', sans-serif;
    margin-top: 30px;
}

.medical-disclaimer p{
    font-weight: bold;
    font-size: 1em;
}

.medical-disclaimer span{
    display: block;
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    font-size: 0.6em;
    font-weight: 400;
    line-height: 1.5em;
}

.results-content{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    margin-top: 50px;
    color: white;
    font-weight: 400;
}

.results-content__column{
    width: 50%;
}

.results-content__column--left{
    padding-right: 50px;
}

.results-content__column--left p{
    margin: 30px 0px;
}

.results-content__column--left ul{
    list-style: none;
    padding-left: 0px;
    columns: 2;
}

.results-content__column--left ul li{
    margin-bottom: 10px;
}

.results-content h4{
    font-weight: 700;
    font-size: 1.5em;
    letter-spacing: 1px;
}

.results-content p{
    font-size: 1.1em;
}

.brain-scans{
    width: 100%;
    background-color: #83b1df;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
}

.brain-scan__columns{
    width: 50%;
    text-align: center;
}

.brain-scan__columns img{
    width: 100%;
    max-width: 180px;
}

.brain-scan__columns span{
    font-weight: 700;
    font-size: 0.8em;
    display: block;
    margin-top: 10px;
}

.page-content--results{
    padding: 50px;
}

.campaign{
    margin-top: 50px;
    width: 100%;
    background-color: #192553;
    border-radius: 85px 0px 85px 0px;
}

.campaign-header{
    border-radius: 85px 0px 0px 0px;
    background: rgb(217,111,92);
    background: linear-gradient(90deg, rgba(217,111,92,1) 0%, rgba(230,151,90,1) 100%);
    padding: 30px;
    text-align: center;
    font-size: 1.4em;
    line-height: 1.5em;
    letter-spacing: 1px;
    color: white;
    font-weight: 700;
}

.campaign-promo{
    width: 100%;
    background-color: #192553;
    border-radius: 0px 0px 85px 0px;
    padding: 50px;
}

.campaign-promo__code{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.campaign-promo__code--column{
    width: 50%;
    text-align: center;
    color: white;
}

.campaign-promo__code--column h3{
    color: #468acf;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    font-size: 2em;
    line-height: 1.2em;
    margin-top: 30px;
}

.campaign-promo__code--column--right{
    padding: 0px 50px;
}

.campaign-promo__code--column--right span{
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 30px;
    display: block;
}

.campaign-promo__code--column--right p{
    font-weight: 600;
    font-style: italic;
    margin-bottom: 30px;
    font-size: 1.2em;
}

.campaign-promo__code--column--right .au-btn{
    padding: 10px 40px;
    display: block;
    margin: 0 auto;
}

.campaign-promo__code--column--right i{
    color: #d35745;
    text-decoration: line-through;
    font-size: 1em;
}

.campaign hr{
    width: 100%;
    border: 1px solid white;
    margin: 50px 0px;
}

.campaign-testimonials{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.campaign-testimonials__column{
    width: 33%;
    text-align: center;
    padding: 20px 30px;
}

.testimonial-image{
    width: 100%;
    max-width: 200px;
    border: 8px solid #d35745;
    border-radius: 50%;
}

.testimonial-name{
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    font-size: 2em;
    line-height: 1.2em;
    margin: 20px 0px;
    text-transform: uppercase;
    color: white;
}

.campaign-testimonials__column p{
    text-align: left;
    color: white;
    font-weight: 400;
}

.campaign-testimonials__column p:before{
    content: '\201c';
    color: white;
    font-weight: bold;
    font-size: 2em;
    margin-left: -15px;
}

@media screen and (max-width: 1200px){
    header{
        padding: 40px 20px;
    }   
}

@media screen and (max-width: 1024px){
    br{
        display: none;
    }    
}

@media screen and (max-width: 900px){
    .page-content{
        padding: 50px;
    }

    .equal-half-part{
        width: 100%;
        margin-bottom: 30px;
    }

    .equal-half--bottom{
        margin-top: 0px;
    }

    .results-content__column{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .results-content__column--left{
        padding-right: 0px;
    }

    .results-content__column--left p{
        margin: 20px 0px;
    }

    .campaign-promo__code--column{
        width: 100%;
        margin-bottom: 30px;
    }

    .campaign-testimonials__column{
        width: 50%;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px){
    .answer_item_wrap h4 {
        margin-top: 0px;
    }

    .proxy_radio{
        width: 20px;
        height: 20px;
        margin-top: 5px;
    }

    .answer_item_wrap{
        background-color: black;
        color: white;
    }

    .active_answer.answer_item_wrap{
        background-color: #192553;
        color: white;
    }

    .answer_item_wrap:hover > .proxy_radio{
        background-color: #192553;
    }

    .answer_item_wrap:hover > .question-choice{
        color: white;
    }

    .active_answer.answer_item_wrap .question-choice{
        color: white;
    }

    body.questions .question-div-main {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 0;
    }

    .results-content{
        width: 100%;
    }

    .campaign-testimonials__column{
        width: 100%;
    }

    .hp-list{
        margin-left: 0px;
    }
}

@media screen and (max-width: 480px){
    body{
        font-size: 14px;
    }

    h1{
        font-size: 2.5em !important;
        line-height: 1.2em !important;
    }

    header{
        justify-content: center;
        padding: 20px;
    }

    .header-items--logo{
        margin-bottom: 20px;
    }

    .page-content{
        padding: 20px;
    }

    .equal-half{
        margin-top: 20px;
    }

    .equal-half-part{
        margin-bottom: 20px;
    }

    .hp-list{
        padding-left: 20px;
    }

    .equal-half--bottom{
        margin-top: 0px;
    }

    body.questions .question-div-main{
        padding: 0px;
    }

    .page-content{
        border-radius: 0px;
    }

    .take-test-for{
        margin-top: 30px;
    }

    .results-content{
        margin-top: 30px;
    }
}