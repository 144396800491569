/* Generated by Font Squirrel (http://www.fontsquirrel.com) on October 23, 2013 */

@font-face {
    font-family: 'gotham-book-regular';
    src: url('../fonts/gotham-book-webfont.eot');
    src: url('../fonts/gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham-book-webfont.woff') format('woff'),
         url('../fonts/gotham-book-webfont.ttf') format('truetype'),
         url('../fonts/gotham-book-webfont.svg#gotham_bookregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gotham-light';
    src: url('../fonts/gotham-light-webfont.eot');
    src: url('../fonts/gotham-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham-light-webfont.woff') format('woff'),
         url('../fonts/gotham-light-webfont.ttf') format('truetype'),
         url('../fonts/gotham-light-webfont.svg#gothamlight') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'gotham-medium';
    src: url('../fonts/gotham-medium-webfont.eot');
    src: url('../fonts/gotham-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham-medium-webfont.woff') format('woff'),
         url('../fonts/gotham-medium-webfont.ttf') format('truetype'),
         url('../fonts/gotham-medium-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'gotham-bold';
    src: url('../fonts/gotham-bold-webfont.eot');
    src: url('../fonts/gotham-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gotham-bold-webfont.woff') format('woff'),
         url('../fonts/gotham-bold-webfont.ttf') format('truetype'),
         url('../fonts/gotham-bold-webfont.svg#gothambold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
  font-family: 'gotham-black';
  src: url('../fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'), 
	   url('../fonts/Gotham-Black.otf')  format('opentype'),
	   url('../fonts/Gotham-Black.woff') format('woff'),
	   url('../fonts/Gotham-Black.ttf')  format('truetype'),
	   url('../fonts/Gotham-Black.svg#Gotham-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}
