/* Add Type Test v.2.0 - CSS */

/* 
General styles
-------------------------------------------------------------------------------------------- */
body { font-family: 'Lato', Arial, sans-serif; background-color: #d9d9d9; font-weight: 300; font-size: 1.6em; line-height: 26px; color: #404040;}
h1 { font-size: 46px; line-height: 58px; }
h2 { font-size: 42px; line-height: normal; }
h3 { font-size: 23px; line-height: 31px; }
h4 { font-size: 18px; line-height: normal; }
img { max-width: 100%; height: auto; } 
a, a:link, a:active { color: #51beaa; text-decoration: underline; outline: none; }
a:hover, a:visited { color: #43b9a1; }

/* 
Common styles
-------------------------------------------------------------------------------------------- */

/* layouts elements */
.section { float: left; width: 100%; padding: 20px 0; clear: both;  }
.title-block { display: block; margin-bottom: 0; background-color: #51beaa; border-radius: 6px 6px 0 0; padding: 20px; margin-top: 60px; }
.title-block h1 { margin-top: 0; margin-bottom: 0; font-size: 34px; }
.block { padding: 20px 15px; display: block; }
.block-lg { padding: 40px; display: block; }
.block-xl { padding-top: 63px; padding-bottom: 63px; }
.block-normal { padding-top: 45px; padding-bottom: 45px; }
.round-corners { border-radius: 6px; }
.no-round-corners { border-radius: 0; }
.pill-left, .pill-right { background: #51beaa; border: 4px solid #12856d; box-sizing: border-box; border-radius: 100px 0 0 100px; text-align: center; padding: 20px; margin-right: -2px; }
.pill-right { border-radius: 0 100px 100px 0; margin-right: 0; margin-left: -2px; }
.bordered-left, .bordered-right { padding-left: 20px; padding-right: 20px; box-sizing: border-box; border-style: solid; border-color: #9d9b9c; border-width: 0 1px 0 0;  margin-right: -1px; }
.bordered-right { margin-right: 0; margin-left: -1px; border-width: 0 0 0 1px; }
.full-left { float: left; width: 100%; box-sizing: border-box; }

/* alignment */
.display-block{ display: block; }
.display-inline{ display: inline; }
.display-inline-block{ display: inline-block; }

/* alignment */
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.alignleft { float: left; margin: 0 15px 15px 0; }
.alignright { float: right; margin: 0 0 15px 15px; }
.aligncenter { display: block; text-align: center; width: auto; margin: 0 auto 20px; }
.valigntop { vertical-align: top; }

/* font types */
.text-montserrat-normal { font-family: 'Montserrat', 'Arial Black', Arial, sans-serif; font-weight: 400; }
.text-montserrat-black { font-family: 'Montserrat', 'Arial Black', Arial, sans-serif; font-weight: 700; }
.text-open-sans-light { font-family: 'Open Sans', Arial, sans-serif; font-weight: 300; }
.text-gotham-black {font-family: 'gotham-black', Arial, sans-serif; }
.text-gotham-book-regular {font-family: 'gotham-book-regular', Arial, sans-serif; }
.text-gotham-book-regular b, .text-gotham-book-regular strong { font-family: 'gotham-bold', Arial, sans-serif; }
.text-gotham-light {font-family: 'gotham-light', Arial, sans-serif; }
.text-sullivan-bevel { font-family: 'sullivanbevel', arial, sans-serif; }
.text-lato-light { font-family: 'Lato', Arial, sans-serif; font-weight: 300; }
.text-lato-regular { font-family: 'Lato', Arial, sans-serif; font-weight: 400; }
.text-lato-bold { font-family: 'Lato', Arial, sans-serif; font-weight: 700; }

/* colors */
.text-white, a.text-white { color: #fff; }
.text-yellow, a.text-yellow { color: #f1dc43; }
.text-green, a.text-green { color: #15aba5; }
.text-gray, a.text-gray { color: #8a8a8a; }
.text-orange { color: #f78a40; }
.bgcolor-white  { background-color: #fff; }
.bgcolor-of-white  { background-color: #f1f1f1; }
.bgcolor-grey { background-color: #f6f6f6; }
.bgcolor-gray { background-color: #757575; }
.bgcolor-light-gray { background-color: #E6E6E6; }
.bgcolor-light-gray2 { background-color: #EBEBEB; }
.bgcolor-blue { background-color: #21b0a9; }
.bgcolor-orange { background-color: #f38d43; }
.bgcolor-light-blue { background-color: #d4f7f5; }
.bg-transparent-white{ background-color: rgba(255,255,255,.88); }
.bg-transparent { background: url(../images/transparent-bg.png); }
.bg-transparent-black { background: url(../images/transparent-bg-black.png); }
.bg-blue-particles { background: url(../images/blue-bg.jpg); background-position: center; background-size: cover;}
.bg-testimonials { background: url(../images/testimonials-bg.jpg); background-position: center; background-size: cover;}
.bg-red-particles { background: url(../images/red-bg.jpg); background-position: center; background-size: cover;}
.bg-white-particles { background: url(../images/white-bg.jpg); background-position: center; background-size: cover;}

/* margins */
.no-margin-top { margin-top: 0; }
.no-margin-bottom { margin-bottom: 0; }
.margin-bottom-30 { margin-bottom: 30px; }
.margin-top-80 { margin-top: 80px; }
.margin-top-60 { margin-top: 60px; }
.margin-top-30 { margin-top: 30px; }
.margin-top-35 { margin-top: 35px; }
.margin-top-20 { margin-top: 20px; }
.margin-top-10 { margin-top: 10px; }
.margin-bottom-60 { margin-bottom: 60px; }
.margin-bottom-30 { margin-bottom: 30px; }
.margin-bottom-20 { margin-bottom: 20px; }
.margin-bottom-10 { margin-bottom: 10px; }
.margin-right-30 { margin-right: 30px; }
.bottom-container { border-radius: 0 0 6px 6px; margin-bottom: 40px; }
.neg-margin-top-30 { margin-top: -30px; }

/* padding */
.padding-block { padding: 40px 20px; box-sizing: border-box; }
.padding-block-header { padding: 10px 0 12px; box-sizing: border-box; }
.no-padding-bottom { padding-bottom: 0; }
.no-padding-top { padding-top: 0; }
.padding-bottom-60 { padding-bottom: 60px; }
.padding-bottom-40 { padding-bottom: 40px; }
.padding-bottom-30 { padding-bottom: 30px; }
.padding-bottom-20 { padding-bottom: 20px; }
.padding-bottom-15 { padding-bottom: 15px; }
.padding-bottom-10 { padding-bottom: 10px; }
.padding-top-150 { padding-top: 150px; }
.padding-top-100 { padding-top: 100px; }
.padding-block-thick { box-sizing: border-box; padding: 40px; }
.no-padding { padding: 0; }
.no-padding-left { padding-left: 0; }
.no-padding-right { padding-right: 0; }

/* buttons */
.btn { font-size: 16px; padding: 25px 40px; line-height: 22px; transition: background-color 0.7s ease; }
.btn-warning { background-color: #f38d43; }
.btn-primary { background-color: #1b64a0; }
.btn-small { padding: 15px 20px; }
.btn-medium { padding: 17px 57px; margin: 10px 0; }
.roundIcon { padding: 5px; background: #fff; color: #f38d43; border-radius: 1000px; font-size: 15px; vertical-align: bottom; }
.btn a, a.btn { color: #fff; text-decoration: none; }

/* forms */
.textinput { background-color: #ffffff; padding: 15px; box-sizing: border-box; height: auto; border-width: 0; box-shadow: 0 1px 2px #999 inset; }

/* font-size */
.large-text { font-size: 16px; line-height: normal; }
.large-headline-text { font-size: 90px; line-height: normal; }
.medium-headline-text { font-size: 55px; line-height: normal; }
.text-small { font-size: 11px; line-height: 16px; }
.text-size-12 { font-size: 12px; line-height: 18px; }
.text-size-14 { font-size: 14px; line-height: 22px; }
.text-size-18 { font-size: 18px; line-height: 24px; }
.text-size-24 { font-size: 24px; line-height: 30px; }
.text-size-30 { font-size: 30px; line-height: normal; }
.text-size-20 { font-size: 20px; line-height: normal; }
.medium-icon { font-size: 36px; line-height: 36px; }

/* misc */
.clear { clear: both; }
.not-visible { visibility: hidden; }
.hidden { display: none; }
.hr-block { border-bottom: 17px solid #51beaa; padding-bottom: 15px; margin-bottom: 15px; }
.hr-dash { border-top: 2px solid #9d9b9c; display: block; margin: 20px auto; text-align: center; width: 40px; }
.header-spacer { float: left; width: 100%; clear: both; }
ul.linklist, ul.linklist > li { display: block; list-style: none; padding: 4px 0 4px 10px; }
.text-underline-none, a.text-underline-none { text-decoration: none; }

/* Angular Animate */
.fade-in-out { transition: 0.5s linear all; opacity: 1; }
.fade-in-out.ng-hide { opacity: 0; }
.change { opacity: 1 }

/* 
Header
-------------------------------------------------------------------------------------------- */
#site-header { background-color: #f38d43; }
#site-header-v2 { box-sizing: border-box; position: fixed; top: 0; left: 0; width: 100%; z-index: 999999; }
#site-header h1 { font-size: 26px; margin-bottom: 0; line-height: normal; margin-top: 0; margin-bottom: 0;  }
#site-header h2 { font-size: 16px; line-height: normal; margin-top: 0; margin-bottom: 0; }
#site-header .btn-head { margin-top: 20px; }
#site-header-v2 .site-new-logo img { width: 108px; height: auto; margin-left: 1px; }
ul.headernav { list-style: none; margin-bottom: 0; margin-top: 10px; }
ul.headernav > li { display: inline-block; zoom: 1; *display: inline; }
ul.headernav > li > a { float: left; padding: 10px 20px; text-decoration: none; color: #444; transition: all 0.5s ease; border-radius: 3px; font-size: 15px; }
ul.headernav > li > a:hover { background-color: #f3f3f3;}
#site-header-v2 .btn-medium { margin-top: 6px; margin-bottom: 2px; padding-top: 15px; padding-bottom: 15px; margin-right: -15px; float: right; }

/* 
Custom Modal
-------------------------------------------------------------------------------------------- */
.modal-dialog.custom {
    width: 480px;
    margin-top: 100px !important;
}

.custom.modal{
	background-color: rgba(0, 0, 0, 0.6);
}

/* 
Container
-------------------------------------------------------------------------------------------- */
#content { clear: both; }
.disclaimer-div  { opacity: 0.5; filter: alpha(opacity=50);  } 

/* 
Homepage Specific Syles
-------------------------------------------------------------------------------------------- */

.landingpage, .bodybg { background: url(../images/homepage-bg-v1.jpg) top center no-repeat #fff; background-size: cover; } 
.landingpage .logo-icon { display: block; padding: 40px 0 10px; }
.landingpage .balloon-wrap { display: block; padding: 50px 0 0; }
.landingpage .start-test-btn { display: block; padding-bottom: 40px; }
.landingpage .start-test-btn a{ margin: 0px 10px; }
.landingpage .container { padding-top: 2px; }
.landingpage .big-number-list { margin-bottom: 30px !important; }
.landingpage .big-number-list p{ font-size: 18px !important; line-height: 22px; }
.landingpage .big-number-list span{ font-size: 35px; font-weight: bold; float: left; margin-right: 8px; }

/* 
Congratulation Specific Styles
-------------------------------------------------------------------------------------------- */

body.congratulation .form-wrap, body.congratulation  .congrats-header { position: relative; max-width: 900px; margin: 0 auto; z-index: 2; }
body.congratulation .form-wrap { background-color: #f4f4f4; border-radius: 6px; padding: 20px; box-sizing: border-box; margin-top: -20px; z-index: 1; }
body.congratulation .form-body { padding: 0; margin-top: -5px; margin-bottom: -7px; }
/*#firstname { background-image: url(../images/name-icon.png); background-repeat: no-repeat; background-position: 10px 50%; }
#email { background-image: url(../images/mail-icon.png); background-repeat: no-repeat; background-position: 11px 50%; }*/
#submitBtn {  margin-bottom: 14px; width: 100%; border-radius: 0; padding-left: 15px; padding-right: 15px; padding-top: 15px; padding-bottom: 15px; box-sizing: border-box;  }
#name, #email { box-shadow: none !important; font-size: 18px; border-radius: 3px; }
#email { margin-top: -14px; margin-bottom: -8px; }

body.congratulation .form-wrapper { margin-top: 5px; }
body.congratulation h1 { font-size: 48px; }
body.congratulation h1.contratulations-title { margin-top: 97px; margin-left: -15px; }
body.congratulation .form-header  h3 { margin-top: -8px; margin-bottom: 14px }

.optin_video { max-width: 620px; position: relative; }

.custom .form-title {
    background: #15aba5;
    border-radius: 6px 6px 0 0;
    padding: 10px 40px;
}

.custom .form-header {
    background-color: #15aba5;
    padding: 20px 40px;
}

.custom .form-header h3{
	margin: 0;
	line-height: 26px;
	border-radius: 6px 6px 0 0;
}

.custom .form-content {
    padding: 20px 40px 0 20px;
}

.custom .form-wrapper .bg-transparent-white {
	border-radius: 0 0 6px 6px;
	margin-top: 0;
}
.custom .form-wrapper .input-field {
    padding: 0 40px 20px;
}

.btn-long{
	margin-top: 30px;
	padding: 15px 60px;
}

.custom-modal-close {
    position: absolute;
    right: 0;
    background: #fff;
    padding: 4px;
    border-radius: 16px;
    border: 2px solid #404040;
    font-size: 12px;
    top: 7px;
    cursor: pointer;
}

@media (max-width: 480px) {
	.modal-dialog.custom {
	    width: 100%;
	    margin-top: 100px !important;
	    margin: 0;
	}
}


/* 
More Types Specific Styles
-------------------------------------------------------------------------------------------- */
.add_type { padding-top: 40px; padding-bottom: 20px; position: relative; }
body.more-types .add_type img { opacity: 0.7; filter: alpha(opacity=70); transition: all 0.6s ease; }
body.more-types .add_type:hover img { opacity: 1; filter: alpha(opacity=100); }
body.more-types .add_type { transition: all 0.6s ease; }
body.more-types .add_type:hover { background-color: #f8e9df; }
body.more-types .add_type a.add_type_link { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
body.more-types .add-types-wrap { padding-bottom: 30px; }
body.more-types .add_type.filtr-item { width: 20%; float: left; padding-left: 10px; padding-right: 10px; box-sizing: border-box; }
.filter-nav-wrap { width: 100%; float: left; margin-bottom: 40px; }
ul.filter-nav { width: 624px; position: relative; margin: 0 auto; padding-left: 0; max-width: 100%; }
ul.filter-nav li { float: left; list-style: none; border-left: 1px solid #fff; }
ul.filter-nav li span { float: left; background-color: #51beaa; padding: 20px 0; box-sizing: border-box; color: #fff; width: 124px; text-align: center; cursor: pointer; }
ul.filter-nav li span:hover, ul.filter-nav li.active span { text-decoration: none; background-color: #f38d43; }
ul.filter-nav li.firstli { border-left: 0; cursor: pointer; }
ul.filter-nav li.firstli span { border-radius: 3px 0 0 3px; }
ul.filter-nav li.lastli span { border-radius: 0 3px 3px 0; }
.add_combo_wrap { float: left; width: 100%; padding: 0 30px 80px; box-sizing: border-box; }
.more_types_div { margin-left: -30px; margin-right: -30px; margin-top: 30px; display: none; }
.more_types_div ul { display: block; list-style: none; padding-left: 0; }
.more_types_div ul li { display: block; padding: 0 30px; background: #c4ece4; }
.more_types_div ul li a { display: block; padding: 20px; border-bottom: 1px solid #fff; font-family: 'gotham-black', arial, sans-serif; text-decoration: none; text-align: left; font-size: 16px; line-height: normal; position: relative; }
.more_types_div ul li a:after { content: "\e080"; display: inline-block; font-family: "Glyphicons Halflings"; font-style: normal; font-weight: normal; line-height: 1; position: relative; top: 1px; position: absolute; top: 50%; right: 10px; font-size: 18px; line-height: 18px; margin-top: -9px; }


/*
Questions
-------------------------------------------------------------------------------------------- */
.progressbar_wrap { padding-left: 0; padding-right: 0; text-align: center; border-style: solid; border-width: 2px; border-color: #fff; box-sizing: border-box; margin-top: 20px; }
.rate-wrap { padding-left: 0; padding-right: 0; min-height: 32px; height: auto !important; height: 32px; }
.rate  { padding: 5px 10px; box-sizing: border-box; border-right: 2px solid #fff; color: #fff; font-size: 21px; line-height: 21px; font-style: 'gotham-light', arial, sans-serif; transition: all 0.5s ease; min-height: 32px; height: auto !important; height: 40px; }
.rate-zero { position: absolute; top: 0; left: 0; z-index: 0; }
.rate-9  { border-width: 0; }
.rate-1 { background-color: #dbe4dd; }
.rate-2 { background-color: #bbdbd7; }
.rate-3 { background-color: #92cfc9; }
.rate-4 { background-color: #69c3be; }
.rate-5 { background-color: #3fb7b2; }
.rate-6 { background-color: #14a39c; }
.rate-7 { background-color: #14a39c; }
.rate-8 { background-color: #14a39c; }
.rate-9 { background-color: #14a39c; }
.progressbar_wrap { padding-left: 0; padding-right: 0; }
.proxy_radio { display: inline-block; zoom: 1; *display: inline; width: 40px; height: 40px; border: 2px solid #9b9b9b; border-radius: 100px; cursor: pointer; transition: all 0.5s ease; }
.proxy_radio:hover, .answer_item_wrap.active .proxy_radio { background-color: #51beaa; border-color: #51beaa; }
.answer_item_wrap input { display: block; text-align: center; margin: 0 auto; }
.answer_item_wrap h4 { color: #bfbfbf; font-size: 21px; transition: all 0.5s ease; }
.answer_item_wrap:hover h4, .answer_item_wrap.active h4 { color: #15aba5; }
.select_source { border: 1px solid #ddd; padding: 10px; border-radius: 6px; font-size: 16px; font-family: 'gotham-light', sans-serif; color: #777; }
.disclaimer_text { display: block; min-height: 70px; height: auto !important; height: 70px; margin-top: -45px; padding-bottom: 1px;  }
.question_div { display: table; width: 100%; text-align: center; margin-bottom: 20px; }
.question_div  h3, .question_div  h2 { display: table-cell; vertical-align: middle; height: 140px; text-align: center; margin-top: 0; text-align: center; position: relative; width: 100%; }
.questionAppwrapper { margin-top: 5px; }
.progressbar_counter h3 { margin-top: 28px; font-size: 24px; }
.questions_body  { margin-top: 45px; }

/*
Video
-------------------------------------------------------------------------------------------- */
.videos .container{max-width: 980px; }
.hero-video {position: relative; top: 100px; }
.videos .combination-head img{width: 40px; margin-right: 30px }
.videos .combination-head h3{font-size: 32px; }
.videos .combination-body{padding: 30px 40px 20px; min-height: 320px; }
.videos .combination-body.nmw{min-height: 100% !important; }
.videos .combination-head.left {text-align: left; padding-left: 30px; }
.videos .combination-body li {font-size: 20px; margin-bottom: 20px; font-weight: normal; }
.videos .border-right {border-right: 1px solid #21b0a9; padding: 20px 0; }
.videos .workbook img{display: inline; width: 30px; margin-right: 20px; position: relative; bottom: 8px; }
.videos .workbook h3{font-size: 30px; display: inline; }
.videos .workbook a{ text-decoration: none !important; }
.videos .workbook-wrap{padding: 20px 40px 0; min-height: 417px; }
.videos .workbook-wrap.nmw{min-height: 100% !important; }
.videos .workbook-content{position: absolute; bottom: 0; }
.videos .workbook-content.nmw{position: relative; bottom: 0; }

.hero-video img.vid {box-shadow: 0 8px 40px #000; }
.hero-video img.play-icon:hover{width: 100px !important; }
.hero-video img.play-icon{position: absolute; margin: auto; top: 0; left: 0; right: 0; bottom: 0; width: 90px; cursor: pointer; }
.hero-video-title {position: relative; top: 50px; }
.hero-spacer {margin-top: 90px; }

.videos .combination-wrap{box-shadow: none !important; }
.videos .combination-body{background-color: #e7e7e7; }
.video-group img{width: 90px; }
.hr-half{display: block; height: 1px; border: 0; border-top: 1px solid rgba(21, 171, 165, 0.3); margin: 1em 0; padding: 0; width: 50%; margin: 0 auto; }
.arrow-section {width: 0; height: 0; border-left: 50px solid transparent; border-right: 50px solid transparent; border-top: 40px solid #fff; margin: 0 auto; }
img.profile-pic-left{width: 250px; float: right; margin-left: 40px; margin-top: 30px; }
img.profile-pic{width: 250px; float: left; margin-right: 40px; }
.healing_add{max-width: 300px; }

.btn-warning-light:hover{background-color: #d39c0c !important; color: #fff; }
.btn-warning-light{background-color: #efb828 !important; color: #fff; }

.hero-video {
	box-shadow: 0 10px 20px #555; 
}

.testimonials{
	font-family: "georgea";
}

.testimonials .testi-content{
	display: block;
	min-height: 120px;
}

.days-30{
	width: 150px;
}

.about-expert{
	font-size: 24px !important;
	color: #fff;
	font-weight: 300;

}

@media (max-width: 980px) {
	.step-title{ padding-left: 0 !important; }
	.step-title h2{ padding-left: 0 !important; font-size: 32px; }
	.step-action{ top: 13px !important; }
	.steps{ top: 25px !important; }
}

@media (max-width: 799px) {
	.review-head-icon{ display: none !important; }
	.hero-spacer{ margin-top: 0; }
	.videos .combination-head img{widows: 30px; }
	.videos .combination-head h3 {font-size: 22px; }
	.videos .combination-body{ padding: 30px 10px 20px; }
	.hero-video-title{ top: 20px; }
	.hero-video{ top: 50px; }
	.hero-video img.play-icon{ widows: 50px; }
	.videos .border-right{ border-right: none; }
	.workbook h3{ font-size: 20px !important; }
	#next_step h2{ font-size: 26px; }
	#next_step h4{ font-size: 20px !important; }
	.healing_list ul{ padding: 0; }
	.healing_list ul li{ font-weight: normal !important; }
	.profile-content{ clear: both; }
	.text-sm-center{ text-align: center; }
	img.profile-pic{ float: none !important; margin-right: 0 !important; }
	img.profile-pic-left{ float: none !important; margin-left: 0 !important; }
	.healing_add{ max-width: 200px; }
	#footer{ margin: 0 !important; padding: 40px 15px 40px !important; }
}

@media (max-width: 767px) {
	.steps{ margin-bottom: 20px; }
	.step-action{ top: 0 !important; text-align: center !important; }
	.step-wrap{ text-align: center; }
}

@media (max-width: 480px) {
	h1 { font-size: 26px !important; line-height: 34px !important; }
	.steps{ margin-bottom: 20px; }
	.step-action{ top: 0 !important; text-align: center !important; }
	.step-wrap{ text-align: center; }
	h3{font-size: 18px; line-height: 24px; }
	.review-head h3{margin-left: 0 !important; }
	thead th:not(:first-child) {display: none !important; }
    td, th {display: block !important; }
    td[data-th]:before  {content: attr(data-th) !important; }
	.review-control{ height: 100% !important; }
    .control-icon{ display: none !important; }
    .review-content{ padding: 25px 15px !important; }
    .review-control{ padding: 10px 0 5px !important; }

    .review-wrap{ padding: 0 !important; }
    .review-content{ padding-right: 15px; padding-left: 15px; }
    .review-head{ border-radius: 0 !important; }

    .form-wrapper{ padding: 0 !important; }
    .padding-block-thick{ padding: 40px 20px !important; }
}

/*
Results
-------------------------------------------------------------------------------------------- */
.result_wrap { float: left; width: 100%; padding-top: 40px; }
.addtype_score { font-size: 230px; line-height: normal; float: left; margin: 0 0 0 0; padding-right: 30px; background-color: #fff; z-index: 2; position: relative; text-shadow: 2px 2px 10px #999; line-height: 230px; }
.traitbox { float: left; padding: 5px; position: relative; transform-origin: right center; transition: all 0.15s ease; box-sizing: border-box; text-align: center; }
.traitbox:hover { transform: translateX(-5%) scale(0.9); z-index: 2; }
.traitsImg { position: absolute; bottom: 5px; right: 5px; width: auto; max-width: none; }
.traits { width: 88%; margin: 120px auto 0; position: relative; }
.action_class_header { margin-top: 70px; margin-left: -40px; margin-right: -40px; display: block; }
.action_class_header img { width: 100%; }
.trait-h3 { display: block; margin: 30px auto 30px; background: #51bea9; padding: 20px; box-sizing: border-box; max-width: 785px; }
.traitbox-1 { max-width: 25%; }
.traitbox-2 { max-width: 50%; }
.traitbox-3 { max-width: 75%; }
.trio_traitbox { width: 75%; margin: 0 auto; }
.trio_traitbox .traitbox-1 { max-width: 33%; }
.optin-title { font-size: 22px; }

.resultbg { background: url(../images/results-bg.jpg) top center no-repeat fixed; background-size: cover; }
.resultbg-reverse { background: url(../images/results-bg-reverse.jpg) top center no-repeat fixed; background-size: cover; }
.add-icon { display: inline-block; zoom: 1; *display: inline; vertical-align: middle; width: 100px; height: 100px; background-size: 100% 100%;  background-repeat: no-repeat; margin-top: -6px; }
.add-icon.icon-small{ width: 37px; height: 37px;  }
.classic-icon { background-image: url(../images/add-type-1.png); }
.inattentive-icon { background-image: url(../images/add-type-2.png);}
.overfocused-icon { background-image: url(../images/add-type-3.png); }
.temporal_lobe-icon { background-image: url(../images/add-type-4.png); }
.limbic-icon { background-image: url(../images/add-type-5.png); }
.ring_of_fire-icon { background-image: url(../images/add-type-6.png); }
.anxious-icon { background-image: url(../images/add-type-7.png); }
ul.type_list { padding-left: 10px; display: block;  }
ul.type_list li { list-style: none; margin-bottom: 10px; display: block; line-height: normal; }
#about_section ul.traits-list { display: block; overflow: hidden; padding-left: 25px; margin-bottom: 30px; }
#action_plan { padding-top: 60px; padding-bottom: 30px; }
#action_plan { background: url(../images/slidebg.jpg) top center no-repeat; background-size: cover; }
#action_slider .action_slider_list { width: 70%; margin: 0 auto; padding-left: 0;  }
#action_slider { padding-bottom: 150px; }
#action_slider .carousel-control.right, #action_slider .carousel-control.left { background: transparent none; }
#results_footer { background: url(../images/footerbg.jpg) top center no-repeat; background-size: cover; }

/* body.results #site-header-v2 { padding-bottom: 6px; } */
#video h1 { padding-top: 6px; line-height: 51px; padding-bottom: 1px; }
#video h3 { font-size: 23px; line-height: 30px; margin-bottom: 13px; }

body.results .block-xl h2 { font-size: 36px; }
.carousel-indicators li, .carousel-indicators li.active { width: 25px; height: 25px; opacity: 0.5; filter: alpha(opacity=50); border-radius: 200px; margin-left: 3px; margin-right: 3px; border-width: 2px; }
#about_section h2 { padding-bottom: 7px; }
ul.traits-list, #about_section p, #about_section h4 { font-size: 18px; }
ul.traits-list li{ line-height: 25px !important; }
#about_section h4 { margin-top: 2px; }

#action_plan h2 { padding-bottom: 23px; }
#action_plan .action_item h3 { font-size: 25px; line-height: normal; }
#action_plan .action_item h4 { font-size: 22px; line-height: 31px; }
#get_help { padding: 86px 0 140px; }
#get_help h3 { font-size: 35px; line-height: 42px; }
#get_help h4 { font-size: 23px; line-height: 31px; }
#get_help ul.linklist { padding-top: 13px; }
#get_help ul.linklist img { margin-right: 14px; margin-left: -5px; }
#results_footer { padding-top: 64px; }
#results_footer h3 { font-size: 28px; line-height: 36px; }
.no-result-icon { margin-right: 5px; }
.fb-link-no-result { padding-bottom: 150px; }

.video-responsive iframe { max-width: 100%; }

.results_video { max-width: 600px; }

.fix-call { position: fixed; bottom: 0; width: 100%; z-index: 999999; }
.fix-call a { display: block; padding: 18px 20px; background-color: #004570; color: #fff; text-decoration: none; text-align: center; font-weight: 500; }
.fix-call a:hover { text-decoration: none; }

/* Section Header */
.steps{ position: relative; top: 32px; }
.step {color: #fff; font-size: 40px; font-weight: 900; font-family: 'Lato'; padding-right: 10px; }
.step-number {color: #fff; background-color: rgba(0, 0, 0, 0.3); padding: 7px 16px; border-radius: 28px; font-weight: 900; font-family: 'Lato'; font-size: 26px; position: relative; bottom: 4px; }
.step-icon {position: absolute; background-color: #30fff5; padding: 5px; border-radius: 5px; color: #21b0a9; font-size: 14px; }
.step-title{ display: inline-block; padding-left: 60px; }
.step-title h2{ font-size: 38px; }
.step-action { position: relative; top: 18px; text-align: right; }
.step-action a{ text-decoration: none; }
.step-action span {margin-left: 10px; position: relative; top: 2px; }

.combination{padding: 0 40px; }
.combination-head{ text-align: center; border-radius: 4px 4px 0 0; }
.combination-head img, .combination-head h2, .combination-head h3{display: inline-block; }
.combination-head h2{font-size: 34px; font-weight: bold; }
.combination-head-icon {width: 50px; position: relative; bottom: 13px; margin-right: 10px; }
.combination-head-icon-small {width: 30px; position: relative; bottom: 8px; margin-right: 20px; }
.combination-body{background-color: #fff; padding: 0 40px 20px; border-radius: 0 0 4px 4px; }
.combination-body h3{margin: 0 0 20px 0; font-size: 20px; line-height: 26px; padding: 25px 0; }
.combination-wrap{ box-shadow: 0 0 18px #000; }
.combination-body li{font-size: 24px; margin-bottom: 15px; }
.combination-body li span{margin-right: 10px; }

.arrow-navigation {width: 0; height: 0; border-left: 100px solid transparent; border-right: 100px solid transparent; border-top: 60px solid #21b0a9; margin: 0 auto; }
.vid-text{margin-top: 20px; font-size: 18px; line-height: 24px; }

.review-head{padding-left: 20px; padding-right: 20px; border-radius: 6px 6px 0 0; }
.review-head-icon {width: 40px; display: inline-block; position: relative; bottom: 5px; }
.review-head h3 {display: inline-block; margin-left: 15px; }
.review-body table{width: 100%; background-color: #E6E6E6; }
.review-body ul {padding: 0; list-style: none; margin: 0; }
.review-control {color: #C1C1C1; font-size: 24px; font-weight: 900; line-height: 28px; text-align: center; padding: 20px 40px 20px 15px; position: relative !important; height: 104px; }
.review-control .control-icon {background-color: #C1C1C1; color: #757575; border-radius: 20px; padding: 5px 6px 7px 6px; position: absolute; right: 10px; top: 38px; font-size: 20px; }
.review-control small{font-size: 14px; }
.border-bottom {
    background-color: #efefef;
    height: 1px;
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
}


.review-body tr:nth-child(even) {background-color: #EBEBEB;}
.review-body tr:hover {background-color: rgba(33, 176, 166, 0.26) !important;}
.review-body tr:hover td.review-control {background-color: #5F8482 !important; color: #fff;}
.review-body tr:hover td.review-control .control-icon { background-color: #fff; color: #5F8482;}
.section-head { z-index: 2; position: relative; }

@media (max-width: 979px) {
	.combination{
		padding: 0;
	}

	.combination-head h2{
		font-size: 24px !important;
	}

	.combination-head-icon{
		bottom: 8px !important;
	}
	
	.combination-body ul li,
	.combination-body h3{
		font-size: 18px !important;
		line-height: 24px !important;
	}
}

/*
404
-------------------------------------------------------------------------------------------- */
.floating_balloon { position: absolute; top: 30px; left: -70px; }
.body-404 { padding-top: 30px; position: relative; }
body.custom-404 { background: url(../images/cloudsbg.png) top center repeat-x #6dd0f7; }


/*
Responsive Fix
-------------------------------------------------------------------------------------------- */

@media only screen and (min-width: 768px) {
	.btn-head { bottom: 0; position: absolute; right: 0; float: right; }
}

@media only screen and (max-width: 992px) {
	.traitsImg { max-width: 100% !important; width: 100% !important; }
	.container { width: 100%; }
	#site-header-v2 .site-new-logo img { width: 70px; display: block; text-align: center; margin: 0 auto; }
	#site-header-v2 .btn-medium { margin-top: 10px; margin-bottom: 10px; margin-left: auto; margin-right: auto;  }
	#site-header-v2 { text-align: center; }
	#site-header-v2 { position: relative; float: left; width: 100%; box-sizing: border-box;  background: #fff; }
	#site-header-v2 .container { float: left; width: 100%; box-sizing: border-box; }
	#site-header-v2 .btn-medium { float: none; }
	.header-spacer { display: none; }
}

@media only screen and (max-width: 768px) {
	.text-md-center{ text-align: center !important; }
	.block-lg { margin-left: 15px; margin-right: 15px; }
	body.congratulation { background-color: #f4f4f4; }
	body.congratulation .form-wrap { background-color: transparent; }
	body.more-types .add_type.filtr-item { width: 50%; }
	.rate { font-size: 16px; line-height: 30px; text-shadow: 0 5px 7px #777; height: 40px; height: auto !important; min-height: 40px;  }
	.rate-wrap { height: 40px; height: auto !important; min-height: 40px; }
	
	h1 { font-size: 40px; }
	h2 { font-size: 30px; }
	h3 { font-size: 20px; }
	h4 { font-size: 14px; }
	
	body.questions { background-color: #f6f6f6; }
	body.questions .container.block  > .round-corners { padding-top: 0; padding-left: 0; padding-right: 0; background-color: transparent; }
	.disclaimer_text br { display: none; }
	.question_div { padding-top: 30px; }
	.question_div h2, .question_div, .question_div h3 { display: block; height: auto; }
	.progressbar .progressbar_wrap { margin-top: 0; }
	.answers_wrap { padding-left: 5px; padding-right: 5px; margin-bottom: 30px; float: left; width: 100%; box-sizing: border-box; }
	.answer_item_wrap { float: left; width: 100%; box-sizing: border-box; background-color: #e5e5e5; padding: 15px 20px; margin-bottom: 7px; border-radius: 6px; transition: all 0.5s ease; cursor: pointer; position: relative; }
	.proxy_radio, .proxy_radio:hover { float: left; border: 3px solid #fff; background-color: transparent; }
	.answer_item_wrap h4 { float: left; margin-top: 0; margin-bottom: 0; margin-left: 20px; color: #404040; }
	/* .answer_item_wrap:hover,*/
	.answer_item_wrap { transition: all 0.5s ease; }
	.answer_item_wrap.active_answer, .answer_item_wrap.active { background-color: #51beaa; }
	.answer_item_wrap:hover h4 { color: #404040; }
	.answer_item_wrap h4 { transition: all 0.5s ease; }
	.answer_item_wrap.active_answer h4,
	.answer_item_wrap.active h4 { color: #fff; }
	/* .answer_item_wrap:hover .proxy_radio,*/
	.answer_item_wrap .proxy_radio { transition: all 0.5s ease; }
	.answer_item_wrap.active_answer .proxy_radio,
	.answer_item_wrap.active  .proxy_radio { background-color: #fff; }
	.proxy_radio_mobile { position: absolute; top: 0; left: 0; height: 100%; width: 100%; cursor: pointer; }
	.disclaimer_text { height: auto; min-height: 0; }
	.questions_body .btn-warning { background: transparent; color: #f38d43; border-width: 0; outline: none; }
	.questions_body .btn-warning .roundIcon { color: #fff; background-color: #f38d43; }
	.traits, .trio_traitbox { width: 100%; }
	.traitbox .hidden-xs { display: block !important; }
	.traitbox .visible-xs { display: none !important; }
	.bordered-right, .bordered-left { margin-left: 0; margin-right: 0; border-width: 0; }
	.pill-left, .pill-right { padding: 10px; }
	
	body.more-types .add_type { border-top: 1px solid #9b9b9b; border-bottom: 1px solid #9b9b9b; margin-top: -1px; padding-top: 20px; }
	body.more-types .title-block { border-radius: 0; margin-top: 0;  }
	body.more-types h3 { font-size: 30px; line-height: normal; }
	body.more-types h4 { font-size: 20px; line-height: normal; }
	body.more-types .add_type .col-xs-8 { text-align: left; }
	body.more-types .type_description h3 { margin-top: 0; } 
	body.more-types .add_type .type_icon img { opacity: 1; filter: alpha(opacity=100); }
	body.more-types  .visible-xs { display: initial !important; }
	body.more-types .add_type a.add_type_link { display: none; }
	body.more-types .add_type:hover { background-color: transparent; }
	body.more-types .types_toolbar { margin-left: -10px; }
	body.more-types .types_toolbar a { display: inline-block; zoom: 1; *display: inline; padding: 10px; background: transparent; }
	body.more-types .more_types_link.active { background-color: #c4ece4; }
	body.homepage .start-test-btn a{ margin-top: 15px; }
	
	body.questions .content-main  .container { padding-left: 0; padding-right: 0; }
	body.questions .question-div-main  { margin-left: -30px; margin-right: -30px; padding-left: 30px; padding-right: 30px; border-radius: 0; }
	.rate-wrap > div { padding-left: 0; padding-right: 0; }
	
	.form-wrapper > div { border-radius: 0 !important; }
	.form-wrapper form { margin-left: -5px; margin-right: -5px; }
	
	body.congratulation .form-header h4 { text-align: justify; text-align-last: center; }
	
	body.homepage .disclaimer-div { margin-top: 70px; }
	
	ul.headernav { padding-left: 0; margin-bottom: 20px; border-bottom: 1px solid #ddd; margin-top: 20px; }
	ul.headernav > li { float: left; width: 100%; box-sizing: border-box; text-align: center; border-bottom: 1px solid #ddd;  }
	ul.headernav > li > a { border-radius: 0; text-align: center; float: left; width: 100%; box-sizing: border-box; padding: 10px;  }
	
	ul.type_list li { font-size: 24px; line-height: 31px; }
	body.results .container, body.results .block, body.results .block-xl { padding-left: 0; padding-right: 0; }
	#results_footer .row { margin-left: 0; margin-right: 0; }
	
	body.results .row { padding-right: 15px; margin-right: 0; margin-left: 0; padding-left: 15px; box-sizing: border-box; }
	
	.videos .workbook-wrap, .videos .combination-body { min-height: 1px; }
	.videos .workbook-content { position: static; }
	.workbook { margin-bottom: 20px !important; }
}

@media only screen and (max-width: 480px) {
	ul.filter-nav li { width: 100%; border-width: 0; }
	ul.filter-nav li.firstli span, ul.filter-nav li.lastli span { border-radius: 0; }
	ul.filter-nav li span { width: 100%; box-sizing: border-box; padding-top: 10px; padding-bottom: 10px; margin-bottom: 1px; }
	body.more-types .add_type.filtr-item { width: 100%; }
	.rate { font-size: 12px; line-height: 21px; }
	
	h1 { font-size: 36px; }
	h2 { font-size: 26px; }
	h3 { font-size: 16px; }
	h4 { font-size: 12px; }

	.alignleft, .alignright, .aligncenter { display: block; text-align: center; width: auto; margin: 0 auto 20px; float: none; }
	.traitbox img { margin: 0 auto; }
	.trio_traitbox .traitbox-1, .traitbox-1, .traitbox-2, .traitbox-3 { max-width: 100%; width: 100%; }
	.pill-left, .pill-right, 
	.pill-left .large-text,
	.pill-right .large-text	{ font-size: 12px; line-height: normal; }
	.addtype_score { width: 100%; margin-right: 0; text-align: center; padding-right: 0; }
	.traitbox .hidden-xs { display: none !important; }
	.traitbox .visible-xs { display: block !important; }
	
	body.homepage .home-description { font-size: 22px; line-height: normal; text-shadow: 1px 1px 0 #aaa; color: #fff; }
	body.more-types .types_toolbar a { font-size: 13px; line-height: normal; padding: 7px; font-weight: normal;  }
	.fb-link-no-result { padding-bottom: 50px; }
}

@media (max-width: 980px) and (min-width: 768px) {
	.img-control{
		text-align: center;
	}

	.img-control img{
		max-width: 460px !important;
	}
}


/*
Overide style
-------------------------------------------------------------------------------------------- */
.btn-normal { padding: 15px !important; }
.relative { position: relative; }
.absolute { position: absolute; }
.static { position: static; }
.fixed { position: fixed; }



